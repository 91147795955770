export var uiClientsConfig = {
    customerUrlPrefix: "cmdemo",
    reservedUrlDomain: "upfronthealthcare.com",
    secretKey:"cFerID8fnM8SdWk7Vt1DywbdeKPFm3ew",
    AdminClientId: "ZmBubZCAg3PqkvLyTHg6D4bMbBB12uPp"
};

export var unlayerConfig = {
    unlayerUserId: uiClientsConfig.customerUrlPrefix
};

export var  reservedFileSystemObjectsFolderId = "5a59d910-6cd4-4fcc-bbd3-15c8cc7bfbc6";
export var  reservedDefaultEmailTemplateId = "758114e6-d7b9-4674-92df-01cc61b75df0";
export var  reservedDefaultPreprationNotesTemplateId = "658114e6-h7b9-4674-92dj-01cc61b75df7";
/**Temporary until permissions are refactored. Comment created 01/10/2019 */
export var  psychographicsEnabled = true;
/* click to text reserved phone number */
export var  clickToTextReservedPhoneNumber = "8152402612";
export var  reservedCodeCategories = {
    reservedBusinessUnitId: "f3aaaccc-d06c-4cfd-bdbc-db53ce7b8b83",
    reservedManualOutboundMessageBusinessProcessSystemEventId: "64197aaa-5209-41d7-bb4f-5d0fd7b3d383",
    reservedCodeCategoryAutomatedPhoneNumberTypeId: "52d6322c-9559-41e7-99c3-28b3c47e2489",
    reservedCodeCategoryManualMessageType: "a5ca312d-baa7-4e98-8a1f-e6883b6fd5ea",
    reservedCodeCategorySecureDriveFileSubTypeId: "ab2df80c-b434-46c7-99c6-0122f2af9a64",
    reservedCodeCategoryLogicalDataTypeId: "1d298fff-d481-4853-ae9f-47b0156ea28a",
    reservedCodeCategoryInvokeNodeId: "94bc7148-1d18-4dbb-8a8d-e92b6663b883",
    reservedCodeCategoryOptOutTypeId: "feebd70c-7a75-4983-9230-f2e6ddc815fd",
    reservedCodeCategoryPauseTypeId: "a9a4a991-2715-471a-ba4a-0a47ef7f9898",
    reservedCodeCategoryRaiseEventTypeId: "977f46a5-77ed-4be2-b81b-7aa207b12662",
    reservedCodeCategoryPauseMeasureId: "eae8dccf-be69-48de-9ed9-df5000a844de",
    reservedCodeCategorySpreadsheetColumnNames: "ac084260-9735-43da-8f0f-ca2a6e79ae01",
    reservedCodeCategoryManualTaskReasonId: "9049fe3a-68c2-406c-bdfe-d39ed4cf26ed",
    reservedCodeCategoryManualTaskCareReasonId: "e6e300aa-a066-4639-a806-11be43c518cd",
    reservedCodeCategoryManualTaskConditionSpecificMessagingReasonId: "e6e300aa-4321-cdx0-c0ol-11be43c518cd",
    reservedCodeCategoryManualTaskGoogleReviewsReasonId: "u6e309aa-a077-4639-a777-11be43c518cs",
    reservedCodeCategoryManualTaskOneOffMessagingId: "5dc28e53-5dce-47be-ae75-06381a9410ac",
    reservedCodeCategoryManualTaskNpsSurveyReasonId: "4944e0f7-4bea-4439-a2c2-6a7d02f0ca86",
    reservedCodeCategoryManualTaskFiveStarSurveyReasonId: "k944e0f8-4bed-4439-a2c6-6a7d02g0ca86",
    reservedCodeCategoryManualTaskPaymentReasonId: "a2050666-8dd0-41ee-a7bf-22ae968c6046",
    reservedCodeCategoryNpsSurveyRatingTypeId: "c044qa09-9ws0-8321-q09x-6a72ols3wq06",
    reservedCodeCategoryNpsDisplayStyleId: "s077qf07-5uj0-8321-a09n-6k72olu3sh03",
    reservedCodeCategoryManualTaskOnDemandReasonId: "737605fa-bd7b-4fd4-8cfe-1b700a55d274",
    reservedCodeCategoryManualTaskOneOffMessagingReasonId: "a880d3a2-fa43-4ba7-95d1-710cb7b473fb",
    reservedCodeCategoryOrgEntityId: "1edeca52-2533-4a42-a34b-492d64b108ab",
    reservedCodeCategoryStatesId: "852cfede-2aca-44c7-9e4a-c7efdad6555c",
    reservedCodeCategoryDocumentTypeId:"a9a83ceb-3912-4a4a-a27a-4bc926470aa8",
    reservedCodeCategoryOtpWidgetThemeId:"1a60f4bf-e009-4f77-8fbf-1fc44d145e6d",
    reservedCodeCategoryIcd9Id: "b7cecb78-c507-4e9e-90bc-4f2d81824e5a",
    reservedCodeCategoryIcd10Id: "bb76f6dd-9953-45fa-bf02-b55e8233495c",
    reservedCodeCategoryAddressTypeId: "1f318409-9cf8-41e3-9e7e-a22fa957e908",
    reservedCodeCategoryEmailTypeId: "6b77f605-958e-4b54-a317-18704b284f39",
    reservedCodeCategorySecureDriveTypeId: "01028ead-9bed-404c-a9f1-f145e5a0c569",
    reservedCodeCategorySecureDriveFolderSubTypeId: "dcb2b4a6-1949-4b84-b2a6-dcacaa0dcc4b",
    reservedCodeCategoryInvoiceTypeId: "ace76dd7-426f-4a04-9a5c-5263445fc4b1",
    reservedCodeCategoryInvoicePaymentMediumId:"aca76zd7-s26s-hh06-ub5c-5163495fc4o1",
    reservedCodeCategoryEventSchedulerType: "9f45ef5f-fec4-4af8-b567-59152d413e20",
    reservedCodeCategoryPhoneTypeId: "7ff5d3dd-34ae-473c-876e-60899e0aa998",
    reservedCodeCategoryOrgEntityRelationshipTypeId: "f7bf5a75-bcdc-4715-b8e9-df664ca6e940",
    reservedCodeCategoryTaskTypeId: "f78bd835-9a1a-4502-9896-142ce6ad317f",
    reservedCodeCategoryOrgEntityTypeId: "1edeca52-2533-4a42-a34b-492d64b108ab",
    reservedTinyUrlSubdomainId: "0596687a-07f9-4fe0-82d1-7a52ee1ab1e3",
    reservedCodeCategoryImageResourceTypeId:"bd907804-7e83-4c70-9c48-7990834079a9",
    reservedCodeCategoryPsychographicId: "2834a36b-f9a2-45a5-96cf-310b0fcc6b52",
    reservedCodeCategorySpecialityId: "2834a36b-f9a2-45a5-96cf-310b0fcc6b92",
    reservedCodeCategoryPrefixId: "924cd9fe-7c6a-4694-9c58-30b363953a8e",
    reservedCodeCategorySuffixId: "9f0cf087-d464-460c-88fc-493388cf8b4e",
    reservedCodeCategoryGenderId: "0de9d728-1f4f-4b18-87f8-86b091da1843",
    reservedCodeCategoryRedoxGenderId:"fbc2b263-c5e1-40dc-999f-b84606523941",
    reservedCodeCategoryDataTriggerEventTypeId: "d2b4cca0-98e0-4d8d-9488-2fe5871821c7",
    reservedCodeCategoryTimezoneId: "9d55a360-1ab3-4de4-9bd0-b1e92f5fc5e1",
    reservedCodeCategoryBusinessProcessTypeId: "80814279-d993-402b-a584-ef1095fc6ba1",
    reservedCodeCategorySettingsValueTypeId: "a6062b21-e819-451d-84ad-e6127758e2e2",
    // reservedCodeCategorySettingsValueSubType: "bd907804-7e83-4c70-9c48-7990834079a7",
    reservedCodeCategoryDurationId: "2c02ad3b-2874-462f-b246-4f06d2b060ae",
    reservedCodeCategorySystemServiceTypeId: "7642592f-cd1b-487e-b114-03a65ce275b1",
    reservedPatientGeneralInformationUpdateId: "8c397010-f323-4f5c-957d-6a0cc1888da2",
    reservedCodeCategorySystemServiceInvokeNodeId: "5d25ef66-21e4-4cf7-b2e0-323d1494565e",
    reservedCodeCategoryPageFlowLinkSocialMediaTypeId: "8a8de8e9-caa5-4566-80fb-f82e20587fcb",
    reservedCodeCategoryWebhookTypeId: "02de51ec-941c-451c-854f-9eb8bc56bf31",
    reservedCodeCategoryPageFlowLinkTrackingTypeId: "23ab437e-7dca-44e0-8548-0f11f0b26dcc",
    reservedCodeCategoryPageFlowTypeId: "8781e8f8-72d1-4b11-a6cd-dce4c1c79508",
    reservedCodeCategoryPageFlowPauseMeasureId: "90cd8c61-b65a-4f50-8e4e-de7d1bb82d9b",
    reservedCodeCategoryConditionEvaluatorId: "q098fe21-9860-560p-a789-me3901fe9023",
    reservedCodeCategoryCompoundOperatorId: "9d55a360-1bb8-4de4-9bd0-b1e30e7da5e1",
    reservedCodeCategoryCountryId: "e34d0p13-076a-720p-q290-b1e45a7da5e1",
    reservedCodeCategoryCountryUSId: "7e98352a-14b3-4283-843d-53080d0d0f9d",
    reservedCodeCategoryAllCountryId: "616e7e43-b9a8-4d9b-899a-9c3d03c86c17",
    reservedCodeCategoryCountryAfghanistanId: "97ca4201-39f4-4fca-b5dd-2deb4adaa918",
    reservedCodeCategoryCountryAlandIslandsId: "731a70cb-1f42-491b-9cab-37f39d592e01",
    reservedCodeCategoryCountryAlbaniaId: "66c97c4e-da5f-404b-94f5-55c9e0398c17",
    reservedCodeCategoryCountryAlgeriaId: "0408989c-be3b-4859-b311-8c551c152ef6",
    reservedCodeCategoryCountryAmericanSamoaId: "a1fb486e-f0dd-4004-9ac7-61e15aa50255",
    reservedCodeCategoryCountryAndorraId: "ff137abd-0014-4cc5-92bf-7a9a5fabbe78",
    reservedCodeCategoryCountryAngolaId: "8811bb19-c8d8-4a4d-b92a-27f84dfe88e4",
    reservedCodeCategoryCountryAnguillaId: "1a463d25-1a9d-4181-a92b-daeeeb38f2af",
    reservedCodeCategoryCountryAntarcticaId: "e02456fb-54fc-4a2f-9a98-23e343150489",
    reservedCodeCategoryCountryAntiguaandBarbudaId: "7c9045ee-4798-4645-b704-362bf3a43369",
    reservedCodeCategoryCountryArgentinaId: "3f0ddefa-4662-4e5a-bef7-1313840a01bf",
    reservedCodeCategoryCountryArmeniaId: "b572201d-6e4b-4011-979c-1825e2ba313a",
    reservedCodeCategoryCountryArubaId: "5e7d3593-313f-431c-9bf9-73b60a3f514c",
    reservedCodeCategoryCountryAustraliaId: "7f06812b-f50b-46b9-86f2-914403ca5bc5",
    reservedCodeCategoryCountryAustriaId: "499ba2f2-6e0b-4fa7-ada7-be748eb35835",
    reservedCodeCategoryCountryAzerbaijanId: "2cb42297-3b17-48a9-87e9-82b285e61075",
    reservedCodeCategoryCountryBahamasId: "738dc5cc-aecd-4489-b6bc-4cc8f1e6a538",
    reservedCodeCategoryCountryBahrainId: "dd5fca49-bba0-472a-974c-8fd953fbc16c",
    reservedCodeCategoryCountryBangladeshId: "5e69eb71-390a-4f6d-b478-3b02f33d3882",
    reservedCodeCategoryCountryBarbadosId: "64cf4949-a271-4314-9633-7d026b5459fe",
    reservedCodeCategoryCountryBelarusId: "02c25aeb-d1ca-47c7-826c-e90964ed2caa",
    reservedCodeCategoryCountryBelgiumId: "7c8c7964-c743-40ee-aa0c-0c68d482b58e",
    reservedCodeCategoryCountryBelizeId: "b79b1b57-9793-4f6e-9298-32e8b7fee5d1",
    reservedCodeCategoryCountryBeninId: "f52a1791-1b5e-4089-a6eb-0998d6de0295",
    reservedCodeCategoryCountryBermudaId: "f044ea23-ddfc-4e39-9c49-7dc6183c4709",
    reservedCodeCategoryCountryBhutanId: "829411b8-d528-41b7-afb1-c37570b2b637",
    reservedCodeCategoryCountryBoliviaId: "2457c77a-e254-4539-9ad7-f1189021945f",
    reservedCodeCategoryCountryBosniaandHerzegovinaId: "c65e8c6c-7935-46df-9999-b868cea63d24",
    reservedCodeCategoryCountryBotswanaId: "73802ac0-443e-4b3e-8d88-60373f3cb565",
    reservedCodeCategoryCountryBouvetIslandId: "966ab467-1e49-44ea-a902-616d85a31353",
    reservedCodeCategoryCountryBrazilId: "4e7ac594-36cc-4090-943d-8816920d27be",
    reservedCodeCategoryCountryBritishVirginIslandsId: "23e65fa6-ba1a-44bd-bd60-798060a6c8ea",
    reservedCodeCategoryCountryBritishIndianOceanTerritoryId: "2239908f-cda3-4fcd-804e-578231456f6a",
    reservedCodeCategoryCountryBruneiDarussalamId: "37f3385e-8fde-4360-8d2c-ed4d662824b0",
    reservedCodeCategoryCountryBulgariaId: "2d2fd79d-cdc4-40bf-a0a6-33ea253784f0",
    reservedCodeCategoryCountryBurkinaFasoId: "1d96e577-6367-490f-b54b-5ad76719de08",
    reservedCodeCategoryCountryBurundiId: "36595b79-10f2-41b4-bae1-72170b1976cf",
    reservedCodeCategoryCountryCambodiaId: "d7f527b1-4d1b-40be-bbda-783658f7d43e",
    reservedCodeCategoryCountryCameroonId: "c8d2d159-ba30-40f6-a4fa-1bb7633138eb",
    reservedCodeCategoryCountryCanadaId: "9e4a55ed-e0eb-4217-9978-a92a98e05076",
    reservedCodeCategoryCountryCapeVerdeId: "7bba7cb8-caf6-403a-8526-434843d8eda4",
    reservedCodeCategoryCountryCaymanIslandsId: "4f2f5b15-cbf6-44e4-a927-cea083a26544",
    reservedCodeCategoryCountryCentralAfricanRepublicId: "72f1be2a-50a6-4b6b-a809-94ed7108dfe4",
    reservedCodeCategoryCountryChadId: "127cbfb2-31f8-467e-916b-9fa6e93c0ae5",
    reservedCodeCategoryCountryChileId: "3f0da513-d84d-474e-be8e-0fc500d1a75f",
    reservedCodeCategoryCountryChinaId: "ae2dda12-dd58-4837-9aa9-bd14c340cedf",
    reservedCodeCategoryCountryHongKongId: "0e882de3-4658-4c69-ae50-e26b1c425395",
    reservedCodeCategoryCountryMacaoId: "7c1a49aa-cbeb-47e0-adbb-02e4ce08f240",
    reservedCodeCategoryCountryChristmasIslandId: "d3927650-98ab-4688-b846-ef999f2a2bd5",
    reservedCodeCategoryCountryCocosIslandsId: "f3913a09-18f1-4ea2-9696-d010ab6c399d",
    reservedCodeCategoryCountryColombiaId: "b1054eb5-144c-47b4-ada3-a7c151810705",
    reservedCodeCategoryCountryComorosId: "c815ea6c-3b7d-4477-927d-0b508d2e1e35",
    reservedCodeCategoryCountryCongo_BrazzavilleId: "1395b283-c0fe-4f50-bbbe-aff6968813ed",
    reservedCodeCategoryCountryCongo_KinshasaId: "6018469a-7e47-4b69-888a-c265ed43350c",
    reservedCodeCategoryCountryCookIslandsId: "901fc62f-3875-4184-94db-4e516e7ed6ca",
    reservedCodeCategoryCountryCostaRicaId: "4bde6e51-6f93-4e8a-b93c-c3e7b7e9fce4",
    reservedCodeCategoryCountryCôtedIvoireId: "382fdfd3-49d0-428d-a1d3-6302dd9b1b55",
    reservedCodeCategoryCountryCroatiaId: "c1824f3d-4cff-4b8f-a587-03cd084deb47",
    reservedCodeCategoryCountryCubaId: "8dfbdeef-9032-4927-a606-ca6f84dc1b5d",
    reservedCodeCategoryCountryCyprusId: "1ed4f725-543f-49b1-925d-03d6e63cbef1",
    reservedCodeCategoryCountryCzechRepublicId: "dfe8b7a3-f4a7-4185-803b-65049d003736",
    reservedCodeCategoryCountryDenmarkId: "eddc2184-6762-4c4d-b352-62501251e199",
    reservedCodeCategoryCountryDjiboutiId: "60197649-a457-476a-a8e0-37b3d0dc80fa",
    reservedCodeCategoryCountryDominicaId: "8e16fe34-28cb-4c88-8a00-eaa0017e4bd7",
    reservedCodeCategoryCountryDominicanRepublicId: "28cb0e38-ed63-459d-ac8a-edd117051211",
    reservedCodeCategoryCountryEcuadorId: "3f1bba82-dcf6-485d-bb5b-ccda1e70d23b",
    reservedCodeCategoryCountryEgyptId: "e54f7602-4810-4dfb-b374-d6d40cb3307c",
    reservedCodeCategoryCountryElSalvadorId: "0dccbd70-8490-484b-b287-12e0fdd983cd",
    reservedCodeCategoryCountryEquatorialGuineaId: "ecc62446-0216-4d59-9cda-22089fe5b3f0",
    reservedCodeCategoryCountryEritreaId: "4fc6fb2d-1b7b-48c2-8a86-51645ebc4fc9",
    reservedCodeCategoryCountryEstoniaId: "9d374571-1f15-4a16-b9be-374b3e8634a1",
    reservedCodeCategoryCountryEthiopiaId: "312552e9-8725-4af7-9959-ab44ac3e8859",
    reservedCodeCategoryCountryFalklandIslandsId: "717e8266-2cdf-4ab1-a6e4-2a09c6da8bc4",
    reservedCodeCategoryCountryFaroeIslandsId: "97942dc4-4a5e-4697-b16e-f9c9c81f629a",
    reservedCodeCategoryCountryFijiId: "49de1e20-ba78-4135-9371-7a4ca5615428",
    reservedCodeCategoryCountryFinlandId: "b3ed4a51-17f5-4abb-b91d-53faba1b9dea",
    reservedCodeCategoryCountryFranceId: "fac7a0d5-b0c4-4607-8641-bb5045addf05",
    reservedCodeCategoryCountryFrenchGuianaId: "a88d59e2-dc47-4a92-bc63-ff0a36ac5132",
    reservedCodeCategoryCountryFrenchPolynesiaId: "dbf89888-6d1b-4d6a-8300-c0b190e66d09",
    reservedCodeCategoryCountryFrenchSouthernTerritoriesId: "7b68309c-9eb2-4564-91d1-f2cc408a6c7f",
    reservedCodeCategoryCountryGabonId: "c8ec5516-505c-464c-be34-c3e3eddb3f90",
    reservedCodeCategoryCountryGambiaId: "7dcbda03-33a0-44e9-ad60-d6eeda532e35",
    reservedCodeCategoryCountryGeorgiaId: "352a3fbc-679f-49d4-b0e8-a6ed44175bd8",
    reservedCodeCategoryCountryGermanyId: "3d6d7e10-fb8d-46be-827b-01bfdc1d32b2",
    reservedCodeCategoryCountryGhanaId: "2b232d31-3a1a-4f0d-9fd9-4f2db0fc1912",
    reservedCodeCategoryCountryGibraltarId: "0f004a49-6f96-4cb9-9c2f-b961eee0cca6",
    reservedCodeCategoryCountryGreeceId: "5263e74d-1b19-4bef-8cfc-563a51dc0fc2",
    reservedCodeCategoryCountryGreenlandId: "09fadc07-2db6-4adb-8f8b-032b695a1d3c",
    reservedCodeCategoryCountryGrenadaId: "b1a15a3b-a510-4533-8c40-4a04ac7c9b8d",
    reservedCodeCategoryCountryGuadeloupeId: "88206141-8d10-4af4-9b9a-bceec971200c",
    reservedCodeCategoryCountryGuamId: "00ae3236-bf7d-4614-a87b-2eeb8e9dd792",
    reservedCodeCategoryCountryGuatemalaId: "db00a731-3078-49cf-8bec-3088b7e23553",
    reservedCodeCategoryCountryGuernseyId: "c44f9463-edb2-4921-a65e-5f659741115c",
    reservedCodeCategoryCountryGuineaId: "7d8e8a82-f1dd-4652-8059-b78e672203c8",
    reservedCodeCategoryCountryGuineaBissauId: "6afac641-a930-4dec-af74-e89d27388077",
    reservedCodeCategoryCountryGuyanaId: "76f853fc-0625-460b-9a2d-f34cae7571a6",
    reservedCodeCategoryCountryHaitiId: "d86ea273-59fc-4e0d-9f5a-50022ac67e75",
    reservedCodeCategoryCountryHeardandMcdonaldIslandsId: "4fb55e2a-27f3-46cd-9ffe-a39501979817",
    reservedCodeCategoryCountryHolySeeId: "5664ca01-306a-4642-8115-a0b60d131b5a",
    reservedCodeCategoryCountryHondurasId: "d4bdb97e-7f50-4bc7-a7f0-8c44c575df7f",
    reservedCodeCategoryCountryHungaryId: "559fe28e-625d-4f2f-a94f-60e3ca1da277",
    reservedCodeCategoryCountryIcelandId: "4a4f249d-d1c1-464b-a5cc-0742fb00b28c",
    reservedCodeCategoryCountryIndiaId: "bb650e5f-f5cc-4cc0-96f3-81904456cc9b",
    reservedCodeCategoryCountryIndonesiaId: "9708605e-d870-451e-9032-d4f018e62a88",
    reservedCodeCategoryCountryIranId: "454ed072-ba42-4928-8492-2256ba8b5916",
    reservedCodeCategoryCountryIraqId: "2c26c58d-d1be-4ebc-a27a-8f6832df467f",
    reservedCodeCategoryCountryIrelandId: "074a8c4b-4c5a-415d-8af9-ab572baea339",
    reservedCodeCategoryCountryIsleofManId: "69e53cb8-da3d-43ac-8ee0-b1b6f74e5ac6",
    reservedCodeCategoryCountryIsraelId: "1e51fc9c-2ce6-4fc9-9ef9-c9ced3dde9ef",
    reservedCodeCategoryCountryItalyId: "3dfdf715-851d-42f2-b79b-ff9f5ff25636",
    reservedCodeCategoryCountryJamaicaId: "a4efabf6-5037-4a67-93c0-e99d1b409c9d",
    reservedCodeCategoryCountryJapanId: "27bcb858-6350-486a-b313-22e254d53eb9",
    reservedCodeCategoryCountryJerseyId: "6b9d90ca-5d8b-423d-8840-c1c4beb7a5ea",
    reservedCodeCategoryCountryJordanId: "c7f869a1-eebe-4d60-9ede-8da94cce0af1",
    reservedCodeCategoryCountryKazakhstanId: "11575d6e-ffc7-4b61-b15e-bdd2566e57bd",
    reservedCodeCategoryCountryKenyaId: "e5f4d34b-a8f8-4247-878a-6bce0076e9a4  ",
    reservedCodeCategoryCountryKiribatiId: "f5d883b0-6ccb-4d06-98f7-ca875b3d52a9",
    reservedCodeCategoryCountryNorthKoreaId: "56bd0ad5-06f9-4a95-81e4-434e576847b1",
    reservedCodeCategoryCountrySouthKoreaId: "726eec6f-2a2d-4965-a1c1-59873454e606",
    reservedCodeCategoryCountryKuwaitId: "1d51fc77-d0d3-40ca-97bc-178809e1efc3",
    reservedCodeCategoryCountryKyrgyzstanId: "9823e1d1-6744-49cd-bf34-17e3c2efe833",
    reservedCodeCategoryCountryLaoPDRId: "e8918456-8394-4534-b104-1877860a1e38",
    reservedCodeCategoryCountryLatviaId: "5d8f94e8-1888-4e44-add7-6fff95b13fa7",
    reservedCodeCategoryCountryLebanonId: "27084fc5-9091-484e-9fee-1f3a48321981",
    reservedCodeCategoryCountryLesothoId: "485a36c7-0f8a-4b20-b4c4-3f5e13fc8bad",
    reservedCodeCategoryCountryLiberiaId: "912dcab9-4130-484f-bd3a-26a2750d922d",
    reservedCodeCategoryCountryLibyaId: "10d2f887-7856-4e2c-a1c7-02f4c06928d8",
    reservedCodeCategoryCountryLiechtensteinId: "50672e72-7136-4ee4-ab4d-be8c21034819",
    reservedCodeCategoryCountryLithuaniaId: "39d2e11f-b5e7-4ec7-9046-0020fd89775f",
    reservedCodeCategoryCountryLuxembourgId: "844ccbac-4036-4d60-aa50-99f918843454",
    reservedCodeCategoryCountryRepublicofMacedoniaId: "18835458-4ba9-4a31-a79e-dcc88c0ba58a",
    reservedCodeCategoryCountryMadagascarId: "e0641a18-2e46-4747-8bb8-45bed43e89bf",
    reservedCodeCategoryCountryMalawiId: "5f7ae3c3-bc75-48a3-ac9d-42f64757e5ed",
    reservedCodeCategoryCountryMalaysiaId: "a611b7d3-4f22-4207-97a0-f75438f024e8",
    reservedCodeCategoryCountryMaldivesId: "7733c09e-8fe2-413e-9ee0-123680708ea3",
    reservedCodeCategoryCountryMaliId: "ce4bb4a0-bf57-47ad-b658-f7d6b350aea4",
    reservedCodeCategoryCountryMaltaId: "cbfa883d-8c48-498e-a6be-f142f77054b6",
    reservedCodeCategoryCountryMarshallIslandsId: "9840a107-5a83-456c-bc84-219517fc49a0",
    reservedCodeCategoryCountryMartiniqueId: "3fabbdde-cd8d-4ffe-9062-620c0b0ed05c",
    reservedCodeCategoryCountryMauritaniaId: "60204e9d-0cfc-4516-a301-b283cbe91f08",
    reservedCodeCategoryCountryMauritiusId: "7e9f94fc-b2c7-48af-8d7e-6e3bf74c8273",
    reservedCodeCategoryCountryMayotteId: "20652ec4-ba81-41d6-9cdb-814ad56302ca",
    reservedCodeCategoryCountryMexicoId: "37c8d416-16c4-4da7-b983-fad336bb7f71",
    reservedCodeCategoryCountryMicronesiaId: "032c0d76-211d-45f4-9bb3-37c62f44923b",
    reservedCodeCategoryCountryMoldovaId: "0f650d73-ccc5-4f49-b111-8c4c6917febb",
    reservedCodeCategoryCountryMonacoId: "435f8a2a-bf66-40d8-b455-d0a81e458ad6",
    reservedCodeCategoryCountryMongoliaId: "20bb05d7-99b5-4eb9-88ef-fd978216aa61",
    reservedCodeCategoryCountryMontenegroId: "111ed8f2-8eb3-4b6a-be53-e843f21a9f2f",
    reservedCodeCategoryCountryMontserratId: "6751e96f-140e-4c8b-9f76-a720f4d26c9f",
    reservedCodeCategoryCountryMoroccoId: "f0a44151-9122-4f03-87d7-65fffef0d465",
    reservedCodeCategoryCountryMozambiqueId: "dfec0ee0-e2bc-409a-b84e-940cb262116a",
    reservedCodeCategoryCountryMyanmarId: "87415541-0941-456b-a081-5322149de72f",
    reservedCodeCategoryCountryNamibiaId: "c8743c67-5ae1-41c2-9ed0-f1af206b8636",
    reservedCodeCategoryCountryNauruId: "baaa8c75-4665-4b96-ae7a-98e8ed18423a",
    reservedCodeCategoryCountryNepalId: "e1161090-83d2-4cf8-a476-b861244d8b7e",
    reservedCodeCategoryCountryNetherlandsId: "10da0a0e-256d-4bd9-b0b4-fd190a5aa36c",
    reservedCodeCategoryCountryNetherlandsAntillesId: "6c13c23e-ff5e-42c1-a82b-1558f370a105",
    reservedCodeCategoryCountryNewCaledoniaId: "b6de0ac8-734c-49e2-9454-21c281b2209d",
    reservedCodeCategoryCountryNewZealandId: "9880b290-b810-40cd-a616-eecdd48d1ed9",
    reservedCodeCategoryCountryNicaraguaId: "68f5eee4-d14e-4a0a-9509-82cc7bd5f7fe",
    reservedCodeCategoryCountryNigerId: "a45f7ae6-a334-4fad-b3a2-7ae37fc32ce9",
    reservedCodeCategoryCountryNigeriaId: "100eb16f-eaa2-43ad-9c40-3abec85f9221",
    reservedCodeCategoryCountryNiueId: "51a85a1b-96a2-4973-9987-cb5c5cec4655",
    reservedCodeCategoryCountryNorfolkIslandId: "bc64aa26-1816-42b9-a2a5-c3135f955941",
    reservedCodeCategoryCountryNorthernMarianaIslandsId: "04073912-26a6-4022-bc83-2dceffb3d0c7",
    reservedCodeCategoryCountryNorwayId: "1ed368b5-0f37-4cce-a97a-207c942af494",
    reservedCodeCategoryCountryOmanId: "ab5f95b6-5992-488f-99d7-1e935d8c5431",
    reservedCodeCategoryCountryPakistanId: "0f22f148-de42-42d7-bb31-f393e1f47eb5",
    reservedCodeCategoryCountryPalauId: "8b9a14b8-0c93-4124-8578-4b56e7fbb1ec",
    reservedCodeCategoryCountryPalestinianTerritoryId: "fb01facd-dddc-420b-bc3d-7c2b1bab454a",
    reservedCodeCategoryCountryPanamaId: "dd639af0-7194-42e8-880a-4cafb1fa5932",
    reservedCodeCategoryCountryPapuaNewGuineaId: "081163f5-dd13-4823-9ec4-cc037f8f9f9f",
    reservedCodeCategoryCountryParaguayId: "32c00af6-7e90-457d-9056-c53b151b2f64",
    reservedCodeCategoryCountryPeruId: "23790fa3-b058-47f2-84c3-08b034c1c921",
    reservedCodeCategoryCountryPhilippinesId: "8b6e2037-24e4-458c-8874-d70e1ab20ed1",
    reservedCodeCategoryCountryPitcairnId: "7a596bd6-a877-4efb-9a27-df5567460f8b",
    reservedCodeCategoryCountryPolandId: "6340b81e-6344-4692-b88d-57981ce60e8e",
    reservedCodeCategoryCountryPortugalId: "1770e9fd-34a3-4ce4-954b-2162622922b0",
    reservedCodeCategoryCountryPuertoRicoId: "3127167a-5907-494b-854e-a37f5d21f392",
    reservedCodeCategoryCountryQatarId: "e5af38b2-4eab-4a48-a9a8-0ca3a0f7811d",
    reservedCodeCategoryCountryRéunionId: "f5f7aa5d-ad0b-41e0-ad3c-3a19aa0e37bd",
    reservedCodeCategoryCountryRomaniaId: "6a10bf9b-3149-4c03-b677-e4db64c372e4",
    reservedCodeCategoryCountryRussianFederationId: "724b98dc-cac0-4f2b-ae44-94e48b8f423f",
    reservedCodeCategoryCountryRwandaId: "c949a520-6577-45a8-87aa-e0007d0a04a0",
    reservedCodeCategoryCountrySaintBarthélemyId: "7e39f2ee-cf56-448e-b9db-d16311295a26",
    reservedCodeCategoryCountrySaintHelenaId: "2aa59268-f9c8-453f-a088-be42ba65468f",
    reservedCodeCategoryCountrySaintKittsandNevisId: "7d240585-0a65-4cdd-ba86-5d5048d44544",
    reservedCodeCategoryCountrySaintLuciaId: "df60a4fc-2d6a-4d28-84ae-2bf6b5df7076",
    reservedCodeCategoryCountrySaintMartinId: "b3a91ac1-317f-49e9-8a4d-fa99bc9b4be0",
    reservedCodeCategoryCountrySaintPierreandMiquelonId: "afbf0f5f-a640-4471-a153-73e3fbe7cc28",
    reservedCodeCategoryCountrySaintVincentandGrenadinesId: "ad8c1a16-f40b-4021-a1c8-461f400bf354",
    reservedCodeCategoryCountrySamoaId: "e46af6cb-6152-4446-b2c3-a311e708fe7a",
    reservedCodeCategoryCountrySanMarinoId: "bbd0c9fd-ced7-4990-8570-29b3ca01c576",
    reservedCodeCategoryCountrySaoTomeandPrincipeId: "4ad87d36-893f-442e-a1fe-e725db60ee78",
    reservedCodeCategoryCountrySaudiArabiaId: "1993f3b1-b846-41a4-aee5-d0e0d40de201",
    reservedCodeCategoryCountrySenegalId: "01a29475-28ee-43ab-868c-bb29b0f8d993",
    reservedCodeCategoryCountrySerbiaId: "c7440d0b-3c59-4e19-9382-bd7867f9eeba",
    reservedCodeCategoryCountrySeychellesId: "37e9bead-284d-410e-a3f2-0fc8b1905a5b",
    reservedCodeCategoryCountrySierraLeoneId: "dcd2e594-c359-4121-b391-783617b8adca",
    reservedCodeCategoryCountrySingaporeId: "71a646a2-8910-41a6-a148-039f7462d15c",
    reservedCodeCategoryCountrySlovakiaId: "47ba9b5a-68ee-41a6-8e31-1e87839cc36f",
    reservedCodeCategoryCountrySloveniaId: "4a575deb-5e76-4366-abdc-ee7a3cb9c8b2",
    reservedCodeCategoryCountrySolomonIslandsId: "0939ceee-bea1-4543-b7f3-dcc1106c93c2",
    reservedCodeCategoryCountrySomaliaId: "f650d9e3-0640-4899-b8a9-eafda7a8246a",
    reservedCodeCategoryCountrySouthAfricaId: "3c224872-8d91-453a-94a0-d00da77c9e13",
    reservedCodeCategoryCountrySouthGeorgiaandtheSouthSandwichIslandsId: "177d272d-d103-4f02-9dcf-6c5997165f8c",
    reservedCodeCategoryCountrySouthSudanId: "d135edac-fa0e-466b-8ba3-4dceab9e7aeb",
    reservedCodeCategoryCountrySpainId: "45277740-9b8a-4e98-a1f2-1477f5103e69",
    reservedCodeCategoryCountrySriLankaId: "7cf3e0f7-ccdc-4450-8df8-9ccc5b431437",
    reservedCodeCategoryCountrySudanId: "741a4b8a-4c9d-4ba3-83f3-f4dc70534923",
    reservedCodeCategoryCountrySurinameId: "72232bc9-4767-4a5a-8044-76c476869548",
    reservedCodeCategoryCountrySvalbardandJanMayenIslandsId: "bc2d9d20-b4d0-4853-9232-128ebff2dbba",
    reservedCodeCategoryCountrySwazilandId: "30f23506-c7c7-4dbd-8613-8868f81374e2",
    reservedCodeCategoryCountrySwedenId: "f7c86f96-0599-4505-a146-1deeea048bf4",
    reservedCodeCategoryCountrySwitzerlandId: "6c66fc7e-4d65-44e5-9035-c61ce3ee5687",
    reservedCodeCategoryCountrySyrianArabRepublicId: "92f83c33-dbdb-48d5-893a-f01f44f01320",
    reservedCodeCategoryCountryTaiwanId: "75c7042b-1dd3-4bf2-bfdb-359d92a9da08",
    reservedCodeCategoryCountryTajikistanId: "f80af3be-caa7-40d5-af8e-ea827294822e",
    reservedCodeCategoryCountryTanzaniaId: "8d712ff7-bc75-45fa-ac43-c50d6182fd64",
    reservedCodeCategoryCountryThailandId: "01b9a8f6-8a0f-4d1f-a556-f768af0ffda4",
    reservedCodeCategoryCountryTimorLesteId: "412832bd-6c89-4bbe-a101-4d494bd151a5",
    reservedCodeCategoryCountryTogoId: "6d54e5fb-9cf8-4327-b8c3-a718c88db56b",
    reservedCodeCategoryCountryTokelauId: "419ee24c-0fc9-4035-b564-be2df1e23b41",
    reservedCodeCategoryCountryTongaId: "ecfb649b-ee2a-46ad-a824-c79e0030d1f2",
    reservedCodeCategoryCountryTrinidadandTobagoId: "70279501-caba-4a9c-9fbc-b341447e498d",
    reservedCodeCategoryCountryTunisiaId: "ee00fd3a-d33f-484e-bb2e-d4191de25542",
    reservedCodeCategoryCountryTurkeyId: "6138d001-3533-4e32-9c4c-872959bee3ab",
    reservedCodeCategoryCountryTurkmenistanId: "76759c7d-e388-4fc2-adee-a885fcd03aab",
    reservedCodeCategoryCountryTurksandCaicosIslandsId: "9acbb621-0273-4d34-bfc7-9c5819a7463d",
    reservedCodeCategoryCountryTuvaluId: "a381fb77-51c5-4c87-9b7b-975878cba27c",
    reservedCodeCategoryCountryUgandaId: "0f97bc52-78e8-49d2-8407-47a8f8c6e6c0",
    reservedCodeCategoryCountryUkraineId: "25aae43b-f5c2-4180-ae53-23899d023d41",
    reservedCodeCategoryCountryUnitedArabEmiratesId: "341095b0-830c-4d17-8adb-915981cecfba",
    reservedCodeCategoryCountryUnitedKingdomId: "2e33d852-6507-4f2a-8af1-69adf8859de6",
    reservedCodeCategoryCountryUnitedStatesofAmericaId: "650dfa3f-51a5-4516-b582-8523496e61fc",
    reservedCodeCategoryCountryUSMinorOutlyingIslandsId: "11d77476-a021-4aba-b672-f190c40161f4",
    reservedCodeCategoryCountryUruguayId: "3f0702ca-7bae-402a-8dd0-58dcb3a7a53c",
    reservedCodeCategoryCountryUzbekistanId: "1710c205-0cf3-47cf-9577-ec48b3c85c57",
    reservedCodeCategoryCountryVanuatuId: "9c969885-94a3-4cfa-a762-0ded926aad91",
    reservedCodeCategoryCountryVenezuelaId: "674f32f4-6f54-49f9-8688-e5b30016b148",
    reservedCodeCategoryCountryVietNamId: "450bf788-868a-4072-bd60-efd95c9b6cdd",
    reservedCodeCategoryCountryVirginIslandsUSId: "b7f11f47-84a0-4509-b5f1-3176345401d4",
    reservedCodeCategoryCountryWallisandFutunaIslandsId: "f3a821ce-91c0-440f-b19c-57045e24e27e",
    reservedCodeCategoryCountryWesternSaharaId: "f9dc0c7f-d181-4e28-9b78-28023c77f2f7",
    reservedCodeCategoryCountryYemenId: "7449ca28-bc8d-48e4-9b26-4a65df2c92ec",
    reservedCodeCategoryCountryZambiaId: "f5a7795b-0f7e-4387-aa43-455e9a142fad",
    reservedCodeCategoryCountryZimbabweId: "0efd65d0-2aaf-4868-b4cb-0321d55c6dfc",
    reservedCodeCategoryVisitTypeId: "a734c307-f308-4905-990d-9c651b65d8a4",
    reservedCodeCategoryManualTaskPriorityId: "d7c891f2-50fc-465f-bc1e-a064ce7d1135",
    reservedCodeCategoryManualTaskTypeId: "748089de-6122-447e-8a42-47b33f7f21e9",
    reservedCodeCategoryPatientManualTaskRelationshipOptionId: "338na7d0-ch95-00da-5296-15df2782dt7g",
    reservedCodeCategoryVisitManualTaskRelationshipOptionId: "ddf327gh-9833-df90-5gh2-64cs3dff07d9",
    reservedCodeCategoryInvoiceManualTaskRelationshipOptionId: "dacb22f2-5159-dkfc-53ad-43dbc7f700ed",
    reservedCodeCategoryInboundSmsManualTaskRelationshipOptionId: "ed5c373a-a64c-44a6-9f2f-f0ca928f9ffe",
    reservedCodeCategoryOneOffMessagingTaskRelationshipOptionId: "b7155708-92d8-42a9-bb0c-fa2e3410e5fa",
    reservedCodeCategoryOnDemandManualTaskRelationshipOptionId: "4f5ca6e8-f820-4d9b-90a8-154addcf46ae",
    reservedCodeCategoryDemoObjectManualTaskRelationshipOptionId: "7f5ca6e8-f565-4d7b-90a8-133addcf33ae",
    reservedCodeCategoryOnDemandChannels: "4f9oi7v8-f820-1q0m-7yb2-154addcf46ae",
    reservedCodeCategoryDaysOfWeekId: "89da0b1f-eba1-098c-7706-084c93fe146r",
    reservedCodeCategoryTwilioPhoneNumberTypeId: "bu0pl23w-x0ow-1p0e-c0pl-m096yze4405e",
    reservedBestPracticeFolderId: "f00b3468-8450-4c08-bf38-199c733c1bac",
    reservedClientSpecificId: "fdcfc327-1f99-429e-ba0c-7883729e5bb7",
    reservedBestPracticeBreastCancerFolderId: "03eeafeb-99c6-40a6-b480-0b94d7dbca1b",
    reservedContentLibraryAttachmentId: "1cc6e88f-0052-4b13-b42d-c67c1694d5d7",
    reservedContentLibraryThumbnailId: "97e75e3b-2fc6-49fd-b44d-7059c74b4a17",
    reservedWellnessBreastCancerContentId:"4965ad0a-932a-4b75-966a-d1517ddf776b",
    reservedCodeCategoryReportTypeId: "3edq2we3-92di-5ol1-a001-i709i217405e",
    reservedCodeCategoryReportChannelId: "buc901e3-lka0-cb01-dpl0-i1opl3e4405e",
    reservedMessageReportId: "c1bs01e3-cl0w-p021-f02l-i1o1ok93ws0e",
    reservedClassificationReportId: "z1gs01y3-cl0m-p026-k02l-l1o1op95ws0e",
    reservedOptOutReportId: "u1kj01w3-cl0a-l071-f02l-i8y1ok05js0l",
    reservedCodeCategoryManualTaskClosedActionId: "49d3d457-2db2-4667-9bcc-598f965bce32",
    reservedCodeCategoryTaskStatusId: "7ab089de-6122-447f-8a42-47b33u7f21u9",
    reservedCodeCategoryFollowUpActionMetric: "98ui6573-10q0-i16f-llb4-355406yt4816",
    reservedCodeCategoryDetectMessageEndId: "1w0o61mm-e4d9-00p1-9b1f-972tqw09ik21",
    reservedCodeCategoryMachineDetectionEnableId: "9i3k61mm-e4d9-p4lm-2m3e-972tqw09ik21",
    reservedCodeCategoryFontAwesomeId: "44418ac7-8ecf-4756-acfa-96a37680e3c9",
    reservedCodeCategorySurveyActionMetric: "68ca7a7b-cd46-433f-a12d-9a1958598ab1",
    reservedCodeCategoryLabResultActionMetric: "7yhg547b-cd46-433f-a12d-9a1958598ab1",
    reservedCodeCategoryFiveStarSurveyActionMetric: "38ca7a7s-cd46-433f-a12d-9a1958589ab1",
    reservedCodeCategoryPaymentReminder: "97ux3073-a1q9-0p1t-66m7-355405t1a516",
    reservedCodeCategoryAppointmentActionMetric: "87se3903-u1t9-0p9t-67h7-377405t1i516",
    reservedCodeCategoryCSMActionMetric: "47an3123-k7p9-0u4t-68n7-399905d1i502",
    reservedCodeCategoryActionMetric: "22dc578e-e4d9-42e5-9b1f-911c94dc3c05",
    reservedCodeCategoryManualTaskStatusId: "52387abb-dce0-4c1f-bf6c-6f15ea91497a",
    reservedCodeCategoryWorkQueueTypeId: "cf035b69-c2b3-4974-81d6-eb5d841a0c41",
    reservedRequestedVirtualAppointementDecidingFactorsTriggerTypeId: "aa8dbf58-be9d-48cc-8f26-e1c66decce22",
    reservedCodeCategoryFileProcessingFileExtensionTypeId: "798cd263-54fa-c62e-fda7-49628dd46532",
    reservedCodeCategoryFileProcessingObjectTypeId: "78sw6573-bb80-io3f-a0b1-355498uc4q16",
    reservedCodeCategoryFileProcessingObjectLocationId: "78nh89wq-bb80-pb09-a0b1-355498tc45em",
    reservedCodeCategoryFileProcessingObjectProviderId: "fj09ols2-09op-ch01-a0b1-35op09kmn2em",
    reservedCodeCategoryFileProcessingObjectPatientId: "gb89ol23-09op-qq0p-bb45-35op90plfg32",
    reservedCodeCategoryFileProcessingObjectVisitId: "gb8pl313-gj90-by7u-pp09-3lk98ij3wg32",
    reservedCodeCategoryFileProcessingObjectInvoiceId: "pl0m9313-gj90-zz65-ol30-3lk9ple32m32",
    reservedCodeCategoryFileProcessingObjectPaymentMadeId: "97f78556-5c66-48e5-a892-8c2892177434",
    reservedCodeCategoryFileProcessingObjectOneOffMessageId: "dl0m9423-gj90-qq65-nl30-3lk9ple32m45",
    reservedCodeCategoryVoiceFlowTypeId: "e9c16926-2704-4bea-9688-e10ff307881f",
    reservedCodeCategoryVoiceFlowBranchTypeId: "c281ae12-52d1-5628-bd0e-a2237b38f83a",
    reservedCodeCategoryVoiceRecordingTypeId: "34567766-r983-453b-f504-ek1098fw6bq6",
    reservedCodeCategoryVoiceRecordingSubTypeId: "98767890-w953-490b-f094-dk1098fi6pq6",
    reservedCodeCategoryMMSMediaTypeId: "q021pl12-52t1-5628-td0t-h2237j38j83h",
    reservedCodeCategoryAudioMMSMediaSubTypeId: "a101ud12-22i1-5628-to0t-m7777m38s83h",
    reservedCodeCategoryVideoMMSMediaSubTypeId: "v221id12-62e1-5628-od0t-m6666m38s83h",
    reservedCodeCategoryImageMMSMediaSubTypeId: "i331ma12-72g1-5628-ed0t-m8888m38j83s",
    reservedCodeCategoryVoiceRecordingGreetingSubTypeId: "97777891-w353-490b-e094-dk1098fi6pq1",
    reservedCodeCategoryVoiceRecordingMainMessageSubTypeId: "98888892-y953-490b-g097-dk1098fi6pq6",
    reservedCodeCategoryVoiceRecordingClosingMessageSubTypeId: "92525893-w053-490b-f090-dk1098fi6pq3",
    reservedCodeCategoryOnDemandMessageActionMetric: "9c545a93-n053-490b-f120-dk1098fi6pq4",
    reservedCodeCategoryLinkReprsentationTypeId: "l281ie12-52n1-5628-bk0e-a2237b38f83a",
    reservedCodeCategoryBusinessCalendarExceptionTypeId: "f3aaxcxc-d86c-4cld-bobc-db83ce9b8x43",
    reservedCodeCategoryBusinessCalendarExceptionSubTypeId: "f3aa45clc-d66c-7cld-b7bc-dc83w968x69",
    reservedCodeCategoryBusinessCalendarType: "c6le1869-162x-6310-f98y-e0s7yi2566z9",
    reservedCodeCategoryPausePlusMinusTimeId: "7uj074a1-57da-7e91-a305-d7960987405e",
    reservedCodeCategoryMedicalServicesId: "a695667a-1175-42a6-8209-79d179c585e0",
    reservedCodeCategoryRedoxCommunicationTypeId: "7ff8587c-1ab6-47c9-844a-8d5be7ad8732",
    reservedRequestedAppointmentEventId: "9d074dc2-9f41-420e-b05a-60779010c88f",
    reservedRequestedAppointmentSubmittedTriggerEventId: "4dfa44cb-5435-410f-99de-86930393335a",
    reservedCodeCategoryOauthUserTypeId: "da2ec847-02d6-4d1f-93ba-0956ff9604dc",
    reservedCodeCategoryOauthUserSubTypeId: "c0i3k847-876t-cd9i-93ba-0909ol9604dc",
    reservedCodeCategoryDepartmentTypeId: "ka2eu847-02s6-4h1f-93bi-0956ff96kidc",
    reservedCodeCategoryWorkAllocationTypeId: "b09408e8-eeec-4b43-8ca8-3a9b277ca650",
    reservedCodeCategoryPreferredlanguageId: "z09408e9-eeec-7b43-8ta8-3a6b277ca421",
    reservedCodeCategoryPageFlowLinkConditionSpecificMessagingTypeId: "dcbc3bff-cf66-4f38-99aa-8acf84e456fb",
    reservedCodeCategoryPageFlowLinkExternalLinkTypeId: "pcbc30of-cp66-4f38-99aa-8aof84e456fd",
    reservedCodeCategoryAppointmentCrudTypeId: "4uj074a1-92di-7e12-a307-i7760987405e",
    reservedCodeCategoryAppointmentDeleteTypeId: "5pa987b9-02ti-7i77-o397-f7123980098s",
    reservedCodeCategoryNpsDrivers: "9la986b9-02th-7i78-k397-f7125980098m",
    reservedCodeCategoryNpsDriversPositiveTypeId: "6uk174a1-92dv-8a12-a397-p786a987405q",
    reservedCodeCategoryNpsDriversNegativeTypeId: "8281ye12-52j1-4629-bk0l-a5637b38h89a",
    reservedCodeCategoryNpsEvaluateScoreTypeId: "0221ye12-52j1-4629-lk0l-a5637b76o89a",
    reservedCodeCategoryManualTaskAppointmentReasonId: "d7le1869-192p-6310-f18x-e1s4yk2566zd",
    reservedCodeCategoryClassificationTypeId: "l3ka45clg-d77c-dcld-b7bc-mr83p968z69",
    reservedCodeCategoryOptInTypeId: "k6ca912q-bha7-4e65-8a2l-e6773b6fd5ed",
    reservedCodeCategorySurveyTypeId: "l3k3ew0pl-200p-pikl-c0b2-mr8323ews09",
    reservedCodeCategoryVisitStatusId: "c09iew0pl-200p-pikl-c0b2-mr8323ews09",
    reservedCodeCategoryActionRequestId: "c09ie3w23-cdxs-pikl-c0b2-mr8323ews09",
    reservedCodeCategoryCustomizableSurveyTypeId: "5cabb5e7-99aa-418d-a212-d0c13ae01bcf",
    reservedCodeCategoryCustomizableSurveyFlowTypeId: "4red25e7-xsde-418d-a212-d0c13ae01bcf",
    reservedCodeCategoryJourneyFlowBranchNodeTypeId:"a0969327-c88a-47d0-8085-3c755c20196b",
    reservedCodeCategoryPaymentInstallmentTypeId: "bgc6e3w23-23er-pikl-c0b2-mr8323ews09",
    reservedCodeCategoryPaymentTypeId: "87ujh3w23-qw34-pikl-c0b2-mr8323ews09",
    reservedCodeCategoryActionReportConfigurationTypeId: "c0pol76yh-3plo-cp09-vpwe-mrd09lgqs09",
    reservedTwoWayMessageBusinessProcessDataTriggerEventId: "23q0olsw-xs09-d320-c0b1-3plw0o2d7c46",
    reservedIncomingTwoWayMessageBusinessProcessDataTriggerEventId: "d5e5db84-1abe-4591-851b-478bbcdb1523",
    reservedCodeCategoryManualTaskClassificationTypeId: "d44d0m13-076a-420p-q290-u1a45a7dk5g1",
    reservedCodeCategoryLocationManualTaskClassificationTypeId: "de035b79-c2b3-4474-71d7-eb5d841e0k42",
    reservedCodeCategoryProviderManualTaskClassificationTypeId: "a121pl12-52q2-5629-td1l-h2237k38j83p",
    reservedCodeCategoryOnDemandManualTaskClassificationTypeId: "4dga55cb-5638-420k-99do-86930397835a",
    reservedCodeCategoryVisitManualTaskClassificationTypeId: "036b90a1-71b7-459b-8000-2d0183be5a00",
    reservedCodeCategoryVisitCSMManualTaskClassificationTypeId: "84a88125-7227-4b3f-a3e9-a9c4c1f1b23a",
    reservedCodeCategoryVisitAppointmentRemiderManualTaskClassificationTypeId: "56fc70c8-d321-40d5-a8c0-0ff58f5ddf56",
    reservedCodeCategoryVisitCareMessageManualTaskClassificationTypeId: "8ff33d2c-e00f-4be0-9e72-31df0d57e0d3",
    reservedCodeCategoryVisitFiveStarSurveyManualTaskClassificationTypeId: "52cce777-5cb8-4d6f-b872-b21383d6abc3",
    reservedCodeCategoryVisitNpsSurveyManualTaskClassificationTypeId: "1d5b9fb7-4726-4f5a-b949-2b037631ad0c",
    reservedCodeCategoryVisitPaymentReminderManualTaskClassificationTypeId: "584cf71d-b680-485f-8163-ec652c8c6523",
    reservedCodeCategoryLocationAppointmentReminderManualTaskClassificationTypeId: "48be1ae7-b527-4e6d-a57d-6d8f4cc6e7c0",
    reservedCodeCategoryProviderAppointmentReminderManualTaskClassificationTypeId: "bccda61d-3aac-410d-95ac-e8c6b14e1167",
    reservedCodeCategoryOnDemandAppointmentReminderManualTaskClassificationTypeId: "53411b2f-8da1-4cbd-9891-01606b65f020",
    reservedCodeCategoryTwoWayMessageTypeId: "b530c02a-1607-4c02-be39-c49298e9ebc3",
    reservedCodeCategoryLinkTypesId: "eb92ecca-b8d5-42ff-a791-714cfb317e3e",
    reservedCodeCategoryObjectiveId: "251f0b4f-f865-4443-a273-51dbe0ad110f",
    reservedCodeCategoryTypes: "4a53a5e0-cb32-4c19-926a-0f5e29936630",
    reservedCodeCategoryCodeCategoryTypeId: "6ba991a7-4d0e-407d-9d0d-35c9229dc688",
    reservedCodeCategoryNpsSurveyObjectiveId: "1217c704-c600-4b72-b7eb-f810f79795b2",
    reservedCodeCategoryAppointmentSchedulingObjectiveId: "4e181a1f-d378-47c4-82d8-27eac9e6fcc2",
    reservedCodeCategoryFiveStarSurveyObjectiveId: "d11733e9-2f0b-433e-9864-778ceb4093b8",
    reservedCodeCategoryFollowUpObjectiveId: "e8c83f1f-c8ca-4bcc-aab9-6b7279cc4d6d",
    reservedCodeCategoryPaymentReminderObjectiveId: "65cbf2d1-40e7-479c-90b7-1b3d4b442ba1",
    reservedCodeCategoryAppointmentReminderObjectiveId: "458062fe-52f9-41d9-b335-755ffc58e201",
    reservedCodeCategoryOnDemandObjectiveId: "f03f68b8-e763-4e4e-8294-e8c90fa24f2d",
    reservedCodeCategoryOneOffObjectiveId: "13402d17-44d5-4b4e-ab17-e6e53777a3e7",
    reservedCodeCategoryFileProcessingObjectiveId: "cd98c4d5-7fa3-4e94-ba42-feb2ff522f29",
    reservedCodeCategoryNpsSurveyOptOutTypeObjectiveId: "289ce81c-33ca-400c-99d7-fe96b95c3d7a",
    reservedCodeCategoryFiveStarSurveyOptOutTypeObjectiveId: "2ef17786-90b8-46e8-af28-a47f58cd3616",
    reservedCodeCategoryFollowUpOptOutTypeObjectiveId: "c7a2fae3-034f-422c-9b77-c8a0af9ea59f",
    reservedCodeCategoryPaymentReminderOptOutTypeObjectiveId: "66d5a745-11cc-4d02-95ac-1c009e6c8516",
    reservedCodeCategoryAppointmentReminderOptOutTypeObjectiveId: "faffec80-0b32-48b6-b099-7f99ff00bb68",
    reservedCodeCategoryConditionSpecificMessagingOptOutTypeObjectiveId: "faffec80-0b32-48b6-b099-7f99ff00cc10",
    reservedCodeCategoryOnDemandOptOutTypeObjectiveId: "d6b39ebc-af17-4bc5-b850-3a3f56f9606c",
    reservedCodeCategoryOneOffOptOutTypeObjectiveId: "6d4dcfd2-57f7-4687-8df5-e5309a23bba0",
    reservedCodeCategoryConditionSpecificMessagingObjectiveId:"6d4dcfd2-57f7-4687-8df5-e5309a23cca1",
    reservedCodeCategorySegmentSourceId: "3180423d-1af6-4031-822d-e835f90acac7",
    reservedCodeCategoryNpsProviderActionMetric: "a85687bd-d6d2-4955-83a2-6f9f8a766dea",
    reservedCodeCategoryNpsLocationActionMetric: "ae0d2027-6227-4dfd-8033-088298d4ef11",
    reservedCodeCategoryReasonId: "ae0d2027-4532-de32-8033-088298d4ef11",
    reservedCodeCategoryUrgentCareReasonId: "ae0d2027-6227-4dfd-8033-08829832ws45",
    reservedCodeCategoryDrugScreenReasonId: "ae0d2027-6227-4dfd-2134-08829832ws45",
    reservedCodeCategoryBloodWorkReasonId: "ae0d2027-6227-4532-8033-08829832ws45",
    reservedCodeCategoryTBTestReasonId: "ae0d2027-45rt-4dfd-8033-08829832ws45",
    reservedCodeCategoryB12InjectionsReasonId: "e43sc027-45rt-4dfd-8033-08829832ws45",
    reservedCodeCategoryVaccinationsImmunizationsReasonId: "ae32ws12-ds2w-4dfd-8033-08829832ws45",
    reservedCodeCategoryOccupationalMedicineReasonId: "ae0d2027-45rt-4dfd-2wq1-0sa87u92ws45",
    reservedCodeCategoryIVHydrationTherapyReasonId: "ws987ujh-45rt-4dfd-8033-08829832ws45",
    reservedCodeCategoryPageflowStartPath: "91debcce-dc82-49ea-be2b-6134de4df93b",
    reservedCodeCategoryExternalUiWorkflowId: "e9bb04b2-bb81-497e-9c97-e1b9e587ba6e",
    reservedCodeCategoryCommunicationChannelId: "0a8c48b2-b865-4df9-bd5a-732238393cfd",
    reservedDemoProcessingDataTriggerEventId: "6a794c75-f606-4c8a-b6e8-12cb25fd6cd8",
    reservedNotifyProviderDataTriggerEventId: "6a794c75-f606-4c8a-b6e8-12cb25fd6c7f",
    reservedGenericIncomingMessageDataTriggerEventId: "558033bc-0a9e-49b4-bbee-e76d8dfe9888",
    reservedInterventionClassificationBusinessProcessDataTriggerEventId: "1ee3ews2-de53-490a-b301-d6c405po910g",
    reservedPatientActivityLogBusinessProcessDataTriggerEventId: "38269f8f-01f2-4550-80af-e73903c30f39",
    reservedPatientFocusActivityLogBusinessProcessDataTriggerEventId: "48569f8f-21f2-4550-80cf-d43903c30g40",
    reservedSecureMessagingDataTriggerEventId: "7a797c78-u607-4u7a-b6e8-12io25lk6cd7",
    reservedCodeCategoryExamRoom: "d47f726f-730a-4ef7-974b-b769e339914c",
    reservedCodeCategorySystemServiceReportTypeId: "0c82686e-a149-40ff-8502-85585b100187",
    reservedCodeCategorySystemServiceAppointmentConsultTypeId: "16eecf97-bb5f-4147-9b22-54fb8d379962",
    reservedCodeCategoryPatientInviteTypeId: "16ee32ws-xc45-1234-9b22-54fb8d379962",
    reservedCodeCategoryLocationStatusId: "16ehgv56-xsdc-vfz2-9b22-54fb8d379962",
    reservedCodeCategoryOneOffMessageLinkTypeId: "374009d7-eae6-47b8-b042-bb57fe8be22d",
    reservedCodeCategoryCardioScreeningReasonId: "e7b8e26c-8950-463d-a284-6bdc89b710fc",
    reservedCodeCategoryWCReCheckReasonId: "2c667c73-a505-409f-a9bc-1faafe37011c",
    reservedCodeCategoryPhysicalReasonId: "722bcc2c-77ec-4e92-aec0-1a461f162740",
    reservedCodeCategorySchoolSportsPhysicalReasonId: "34d16d8e-bdf4-43a4-93ae-5e11dcdb9816",
    reservedCodeCategoryCOVID19AntibodyTestingReasonId: "8c76fb35-c1f9-4cf5-9865-b4223c3df630",
    reservedCodeCategorySportsPhysicalReasonId: "e1bffb42-14fc-4f08-a9c1-19b199be7265",
    reservedCodeCategoryVirtualUrgentCareReasonId: "e650de86-378d-437c-9298-83519f4bfabd",
    reservedCodeCategoryPorzioWCFollowUpReasonId: "4d57488c-e790-4ce7-b18c-e47c475e38df",
    reservedCodeCategoryPorzioSBFollowUpReasonId: "9637c794-b6f2-4644-8d3b-5bcecaab13fa",
    reservedCodeCategoryPorzioHeadStrongFollowUpReasonId: "0dadfa40-20d3-4bd9-a26c-9ce4bb376671",
    reservedCodeCategoryPorzioCardiologyPatientReasonId: "14280fe9-a8f4-4810-8f79-da8b3627400b",
    reservedCodeCategoryCHOCChildrenPediatricianReasonId: "b4e8672f-b381-464a-9934-c24994c009d5",
    reservedCodeCategoryMentalWellbeingReasonId: "ea1afec8-dc11-470c-ba2a-2fe77686593d",
    reservedCodeCategoryPhysicalTherapyReasonId: "4b6ca680-9769-400b-96b2-071a87baa022",
    reservedCodeCategoryWorkCompReasonId: "843d3274-113c-4bd6-a840-600f6dd01566",
    reservedCodeCategorySchoolPhysicalReasonId: "7232e459-89ad-4692-b6a5-393cff178543",
    reservedCodeCategorySmartBeatCardioScreeningReasonId: "1a1e545c-bc83-4b74-a3fb-f56a736d62a9",
    reservedCodeCategoryPATExamReasonId:"4427bab6-2bcf-446c-9395-a5797c5092d0",
    reservedCodeCategoryFluShotReasonId:"787e7162-1543-4c52-afb4-6bf48ffeb6d5",
    reservedCodeCategoryUserEthnicityId: "94dc8135-754a-4027-9171-1bbc4f56896c",
    reservedCodeCategoryProviderDashboardPatientReferralId: "b33bf08c-7d92-4230-8a97-3a85632d257c",
    reservedCodeCategoryProviderDashboardBestFormOfContact: "61064069-6c1b-49ce-90ba-1148d4a58a01",
    reservedCodeCategoryUserRaceId: "9cae48a4-cca7-4f3d-b33a-69eab7945d99",
    reservedCodeCategoryCustomFieldId: "9cae48a4-cca7-4f3d-wase-69eab7dxcf34",
    reservedCodeCategoryAppointmentTimeSlotId:"ced50bab-c59e-49ed-9c6d-bb1a87e960ca",
    reservedCodeCategoryDiagnosisCodeTypesId: "11a56655-b072-4e0d-8791-58fd3a67d572",
    reservedCodeCategoryProcedureCodeTypesId: "7bf87eb7-22d3-45d3-a59b-11f51e4f3c65",
    reservedCodeCategoryWidgetTypeId: "413ed430-7ac5-4844-b4a3-83011794540f",
    reservedCodeCategoryInterventionPriorityTypeId:"a5af4fc3-b153-4b1a-92ff-06949d4542f7",
    reservedCodeCategoryInterventionLowPriorityId: "abde038c-3e39-4191-b665-bd259df0d450",
    reservedCodeCategoryInterventionMediumPriorityId: "ad09ecbb-aab4-4059-bec3-6c6bffb6a6b7",
    reservedCodeCategoryInterventionHighPriorityId: "65905f96-92a6-4790-95b6-196fd20ea17f",
    reservedCodeCategoryInterventionTypeId:"a5af4fc3-b153-4b1a-92ff-06959s4542f9",
    reservedCodeCategoryInterventionReasonId:"528dd3d5-dc11-4aa4-bf08-fa758dce4159",
    reservedCodeCategoryInterventionFeverReasonId: "a8fa76b2-0915-4b5c-ac35-0c6faa5965c6",
    reservedCodeCategoryImageResourceType: "433a489e-12e9-4529-a176-0b2104d573c7",
    reservedCodeCategoryVideoResourceType: "fcd0489e-12e9-12qa-a176-0b2104d573c7",
    reservedCodeCategoryActivitySessionType: "az202ws3-12e9-12qa-a176-0b2104d573c7",
    reservedCodeCategoryInterventionStatusId:"2b2a6aa8-46f6-4945-b7fe-bbae46c1085f",
    reservedCodeCategoryInterventionOpenStatusId:"0955c19d-7992-49aa-a4e0-cc8d31e35517",
    reservedCodeCategoryInterventionInProgressStatusId:"0cd14bdc-feb2-4a6d-bc69-0858a1129c11",
    reservedCodeCategoryInterventionCompletedStatusId:"3d75af8c-eafa-4c0a-b053-f29ac5956b42",
    reservedCodeCategoryInterventionClassficiationId:"6ec833d9-eb86-4462-ac1e-044cecae77d4",
    reservedCodeCategoryActivityLogClassficiationId:"42c4a3d9-61d2-4872-96fb-979380512387",
    reservedCodeCategoryFocusActivityLogClassficiationId: "42c4a3d9-61d2-4872-96fb-979380512367",
    reservedCodeCategoryInterventionCommunicationChannelId:"8ec077d9-eb86-4462-ac1e-077cecae77d0",
    reservedCodeCategoryRatingReferralId: "7ec077d9-cb86-9462-dc1e-177cecae77d4",
    reservedCodeCategoryRedoxDataModelId:"db73942b-1ddc-4dad-be1b-2be20704ae87",
    reservedCodeCategoryPatientAdminDataModelId:"10fff2dc-d319-4422-84d3-30414e9eba2d",
    reservedCodeCategoryOrderDataModelId:"dce59936-fde6-4364-9249-9d644935501f",
    reservedCodeCategoryMedicationsDataModelId:"04e8e1a2-4ba8-4957-8314-d0d426bb5821",
    reservedCodeCategorySchedulingDataModelId:"f5cc6860-1b7a-41c8-8248-0a52542d3942",
    reservedCodeCategoryNotesDataModelId:"b3774034-e8ce-4ddd-a782-f38a7922ba02",
    reservedCodeCategoryAlertActivityResponsesId:"71ec43ec-db94-4dd6-9bdd-22f23cf7fdfe",
    reservedCodeCategoryClinicalSummaryDataModelId:"a167e7ec-56d8-44c1-a6a2-c5940f4eca2b",
    reservedCodeCategoryFlowSheetDataModelId: "b1c359d5-53f8-4d41-a87d-903aea8331ec",
    reservedCodeCategoryPatientIdentifierTypeId:"a167e7ec-56d8-44c1-a6a2-c5940f4fca2e",
    reservedCodeCategoryResultsDataModelId:"509aa4be-9f25-4d55-a36b-e7527704c43c",
    reservedCodeCategoryOnDemandMessagingInterventionTypeId:"a5af4fc3-b153-4b1a-92ff-16859s4542f1",
    reservedCodeCategoryPaymentReminderInterventionTypeId:"b5ac4fc3-b153-4b1a-92ff-06959a4542f9",
    reservedCodeCategoryFiveStarSurveyInterventionTypeId:"a5bf4fd3-b153-4b1a-92fg-06959s4542f9",
    reservedCodeCategoryNPSSurveyInterventionTypeId:"b6af4fc3-b153-4b1a-92ff-06949s4542f2",
    reservedCodeCategoryLocationNPSSurveyInterventionTypeId:"c5af4fc3-b153-4b1a-92ff-06959s4542f9",
    reservedCodeCategoryAppointmentReminderInterventionTypeId:"d5af4fc3-b153-4b1a-92ff-96959s4543f9",
    reservedCodeCategoryProviderNPSSurveyInterventionTypeId:"e5af4fc3-b153-4b1a-92ff-06059s4541f9",
    reservedCodeCategoryCareMessagingInterventionTypeId:"f5af5fc3-b153-4c1f-92ff-06959s4542f9",
    reservedCodeCategoryConditionSpecificMessagingInterventionTypeId:"g5af1fc3-b153-4b1a-92ff-06359s4512f9",
    reservedCodeCategoryOneOffMessagingInterventionTypeId:"h5af8fk3-b153-4b1a-92ff-06909s4532f9",
    reservedCodeCategoryOnDemandNoResponseInterventionId: "20e432be-9d38-4da9-979b-c5097c4505f4",
    reservedCodeCategoryPaymentReminderBillingInterventionId: "2ba1c390-c2e7-4ba8-8c9b-7e5072fbf114",
    reservedCodeCategoryFiveStarNegativeSurveyInterventionId: "38a8c8ee-1916-4acc-8b7a-0da793f12291",
    reservedCodeCategoryNPSSurveyNegativeRatingInterventionId: "99ec3127-877c-4c95-8ff2-8cefe3ad621e",
    reservedCodeCategoryLocationNPSNeutralSurveyInterventionId: "d0872bce-9a83-4d53-be74-bf800c789bfc",
    reservedCodeCategoryAppointmentReminderConfirmInterventionId: "dc5f3da9-a6ec-498b-96ca-88ac7cb1673c",
    reservedCodeCategoryProviderNPSNeutralSurveyInterventionId: "e4d65feb-cb53-4a97-bd27-468b80fded3a",
    reservedCodeCategoryCareMessagingCallInterventionId: "947dbca3-8a10-4262-8d4f-7b10d1a84bc7",
    reservedCodeCategoryConditionSpecificMessagingRequestedCallInterventionId: "59353ff7-164b-42bb-849d-64bf1c11dc20",
    reservedCodeCategoryOneOffMessagingInvalidResponseInterventionId: "93bddc83-1eed-4086-adaf-2e40054ee104",
    reservedCodeCategoryLabResultObjectiveId: "xd98y4d5-3fag-4el4-bp42-feb9ff572f2h",
    reservedCodeCategoryLabResultOptOutTypeObjectiveId: "k7a5faeu-084f-429c-hb77-c8j0af9ee57x",
    reservedCodeCategoryFiveStarDrivers: "6083b5b5-89cb-430d-a571-f4ef4aa630bc",
    reservedCodeCategoryFiveStarDriversPositiveTypeId: "add50c06-1040-4e88-8f99-3c646bc70b3d",
    reservedCodeCategoryFiveStarDriversNegativeTypeId: "e740fd14-804a-4460-a1e7-8b40810c86b4",
    reservedCodeCategoryGoogleRatingsTypeId: "4184397e-33d5-40d8-adee-87b4175fe97c",
    reservedCodeCategoryFacebookRatingsTypeId: "cd666466-746b-416f-a6a4-4052b1f1e4a8",
    reservedCodeCategorySocialMediaReviewsStatusTypeId: "bf93359a-2777-45f8-9566-5591679e57ec",
    reservedCodeCategoryDatesOfMonthId: "a5eed1c4-61f8-40aa-a390-0403430147b8",
    reservedCodeCategorySalesforcePatientIdentifierTypeId: "d169e8ec-57d7-44d1-e6e2-c7941f5fda2f",
    reservedCodeCategoryPatientbondPatientIdentifierTypeId: "c177a7ea-76e8-34b1-a7a2-d5841f7fce2a",
    reservedCodeCategorySalesforcePBLogTypeId: "ae0d2027-2547-ha12-8227-088298d4ef11",
    reservedCodeCategorySocialMediaReviewClassificationId: "68bf77dc-2233-476e-90c0-9d797e499fe4",
    reservedCodeCategoryCommonTestResultStatusId: "14a9f740-3916-4621-878f-c6f2f9a2efb0",
    reservedCodeCategoryDevice: "22dcvbgt-90ol-42e5-9b1f-911c94dc3c05",
    reservedCodeCategorySmartMeterMailingMethod: "eb119e68-514f-439f-9456-f024af3219a1",
    reservedCodeCategorySmartMeterUP2: "3f678a5c-eac4-4afc-b89d-ed608c109ff2",
    reservedCodeCategorySmartMeterMail: "98a5b0af-da1e-4269-b416-0f51a08fa419",
    reservedCodeCategoryBodyTraceBloodPressureDevice: "22dvf456-90ol-3wsz-9b1f-911c94dc3c05",
    reservedCodeCategoryIGlucoseBloodPressureDevice : "de728fb2-2db1-48b0-ad0a-0717f0229ffc",
    reservedCodeCategoryIGlucoseBloodGlucoseDevice : "c50fd9ba-55a2-4b48-a532-dcf0e66c7962",
    reservedCodeCategoryBodyTraceWeightScaleDevice: "90olf456-gtrl-3wsz-9b1f-911c94dc3c05",
    reservedCodeCategoryJourneyFlowRedirectTypeId: "3f9bc45a-33b0-4c0c-94f4-da2edf8cdbf5",
    reservedCodeCategoryJourneyFlowRedirectSocialMediaTypeId: "382cec32-038f-4062-9492-97f603dddd49",
    reservedCodeCategoryJourneyFlowRedirectConditionSpecificMessagingTypeId:"6d640646-5b49-40bc-aea3-740b4e09dda0",
    reservedCodeCategoryJourneyFlowRedirectExternalLinkTypeId: "4dced74e-3bd5-4a38-82f7-37a64c53530e",
    reservedCodeCategoryCustomizableSurveyResultType: "5de3d728-1f4f-4b18-87f8-87d637ha3682",
    reservedCodeCategorySystemServiceStatus: "9ea847e5-955d-406e-92fa-1df1e63a0bbe",
    reservedCodeCategoryGenericIncomingMessaging: "0414e792-eec1-4a6a-b061-f9b83582a0d5",
    reservedClick2TextBusinessProcessDataTriggerEventId: "faa93711-bca5-4ac0-90e4-7088a8a9daf6",
    reservedImageResourceSecretKey: "48fef0a341cf7cae1ee9cb987d4b0fdbbda3bc7a3b4ddc291d77103f66ed1225b851c5501e71fd0876289eb6c690a28d42d505a502b6b0ae7fd6ef93af054f5f",

    reservedHealthTrackResultTriggerObject: "HEALTH_TRACK_RESULT_OBJECT",
    reservedHealthTrackPatientResultBusinessProcessDataTriggerEventId: "62432f38-7e8e-46c4-9b2b-fed8f39e56e1",
    reservedOnboardingTutorialTopic: "6c183afe-e1ae-4fe6-bb25-472c76df46c1",
    reservedEducationContentTopic: "ab42b82f-2531-44d4-a02d-41b038653414",
    reservedTroubleshootTopic: "6da35e87-130f-4a38-bfd9-bb5a084075af",
    reservedFAQTopic: "d0138fee-261a-495c-ad73-ee7cf7173705",
    reservedWearTimeTopic: "7462c0ee-f3af-4540-8283-846bdb679640",
    reservedStepCountTopic: "4b7c8c3e-d2a4-4b6c-b3a4-e019ba526146",
    reservedLiveWestTopic: "25a6d694-a217-4b30-8e03-ea8c832bb03f",
    reservedHelpTopic: "457252bc-038c-4855-957c-1abe3f6a39a8",
    reservedMobileAppPatientStoriesTopic: "5229e315-a662-4a60-bf91-3d158d257a29",
    reservedMobileAppVideosTopic: "f48dd847-b1e1-4719-9dad-39faa4ddac8d",
    reservedOnboardingTutorialIcon: "9e206c95-403b-4949-a15d-c78563cc581a",
    reservedEducationContentIcon: "b7509c78-04fd-4ed7-9c8b-baabb7f633da",
    reservedTroubleshootIcon: "ec1ffd99-d8a8-43f6-a0cf-31cc45565d1e",
    reservedFAQIcon: "74a4fc55-fe57-4c86-969e-4eff18520a87",
    reservedWearTimeIcon: "c48d83e0-50e6-4ebd-ba65-fd6e5eb14863",
    reservedStepCountIcon: "4ca4f340-d2bf-43d8-98f0-7a5be809d2ea",
    reservedHomeNavbarIcon:"0c4b9943-9a44-4a5c-840c-6f180a7bd66b",
    reservedMessagingNavbarIcon:"0bf75c27-4aea-4bb1-8c75-29aac2398a89",
    reservedAlertsNavbarIcon:"261b2370-8fd4-4279-b091-2741fa8e2ba2",
    reservedAccountNavbarIcon:"9f01d7a5-4266-4f31-8baa-ecbe60db0794",
    reservedMeasureNavbarIcon:"b5c4431a-b1b6-49c3-9f69-a5add035947c",
    reservedLearnNavbarIcon:"876e65d1-8cc0-4f3d-a42c-a2ac87dce79a",
    reservedHeartRateServiceIcon:"9a629576-2c09-4693-9dba-8ca4d4c03238",
    reservedLiveWestIcon:"2e933805-deeb-41e1-ae5e-edd980523fb3",
    reservedHelpIcon:"a297900b-8261-4bb0-9baf-94d24cc2f74a",
    reservedMobileAppPatientStoriesIcon:"c71bbd9a-cd2b-42e5-8c80-811532fd88b5",
    reservedMobileAppVideosIcon:"be368f87-54b8-4714-83e6-3b7b82d78c42",
    reservedCallIcon:"f5380e99-f6d2-4711-946e-e5000605b0ed",
    reservedEmailIcon:"fcfb4a15-e363-4e7c-80ff-8bd21fea2b1b",
    reservedTopbarLifeVestIcon:"34880861-aeb1-4511-b0fe-9710779a0ec8",
    reservedTopbarHelpIcon:"c41916e7-03c3-4f5d-a116-e6c49bf62480",
    reservedTopbarSavedIcon:"a0a0c845-cd1e-4f26-973d-1bf44808994f",
    reservedCodeCategoryFunctionalCategoryId: "ea0a07c7-a675-4aa5-ac13-30b9709a6ed6",
    reservedCodeCategoryQueueManagementViewId: "70a2fffd-e2ec-4424-9092-977b36f2306b",
    reservedCodeCategoryJourneyContextViewId: "57e8678f-0214-4842-a439-d78c232f3e7a",
    reservedCodeCategoryViewNpsSurveyId: "5393b0c5-5acb-4b9e-9a26-36d2afebb0fd",
    reservedCodeCategoryViewFiveSurveyId: "2f3dfc2e-3bd6-4d03-85f2-af8d66872394",
    reservedCodeCategoryViewPaymentReminderId: "111bd860-e7b7-4f58-ac3c-3c4b3172c19a",
    reservedCodeCategoryViewFacebookReviewId: "892def9e-53b2-4b13-ba50-591a40e8c9cf",
    reservedCodeCategoryViewGoogleReviewId: "e651a37e-84ff-4994-92fb-c5b0414158e1",
    reservedCodeCategoryViewSocialMediaReviewId: "3098d7bd-8b94-430c-9df8-4f0423569571",
    reservedCodeCategoryViewDeviceId: "5517a5e0-a056-4e23-92f5-dabc6699f49f",
    reservedCodeCategoryViewVideoConsultId: "41a62e8c-8a5b-412f-8471-a4644b688b47",
    reservedCodeCategoryViewQuickConsultId: "49f24251-fb72-4c28-a8cb-56bb7b3c61da",
    reservedCodeCategoryViewOfficeVisitId: "f89da134-0310-4f4c-8c43-cb98c0503448",
    reservedCodeCategoryViewAppointmentReminderId: "407ac8d8-cc66-4afb-aa13-4a588bb5faf7",
    reservedCodeCategoryViewLabcorpLabResult: "6c9520f8-f6da-47da-8e07-ae90e06583c4",
    reservedCodeCategoryViewHealthTrackRxLabResult: "de62b395-5006-480d-866c-f8a4318372cb",
    reservedCodeCategoryViewWestPacLabResult: "67a6fbb3-129a-425d-be9f-88451ff4a46a",
    reservedCodeCategoryViewCSMId: "425c3b62-c9b7-417c-80fb-62ee6f67f553",
    reservedCodeCategoryViewPaymentId: "51df9435-919f-437c-9370-4fd6db388b72",
    reservedCodeCategoryViewGenericLabResult:"sf2770fa-932e-4h20-847b-f3fb5571b8d7",
    reservedCodeCategoryViewLimsabcLabResult:"jk2770fa-932e-4l20-847b-k3fb5571b8d6",
    reservedCodeCategoryViewFollowUpId:"e9ab7cdc-bce9-4f8e-ae9b-ca0e08c6edb2",
    reservedCodeCategoryFileProcessingObjectGuarantorId: "64nh89wq-bb80-pb09-a0b1-355498tc45fh",
    reservedCodeCategoryFileProcessingObjectMedEvolvePaymentsMadeId:"acb1772f-4407-43c0-9a9f-1de91d8936db",
    reservedCodeCategoryOauthUserChannelId: "42818de3-7a86-4713-8cba-3827fd506747",
    reservedCodeValueTwilioCallsChannelId: "9b439b3a-1e94-4ded-8539-1a382840681f",
    reservedCodeValueEmailChannelId: "dd61d07e-0056-4b66-8b07-2e20ee1ea36a",
    reservedCodeValueMessagingChannelId: "11a0a52b-5c4c-45b4-b951-37e5ddc84229",
    reservedCodeValueTaskChannelId: "ecda5b9f-c2c3-4b70-9c3e-d3a6d000855f",
    reservedCodeValueSocialChannelId: "d88af232-da45-4a37-a8ad-5a3d50279804",
    reservedPasswordGrantTypeClientId: "ZmBubZCAg3PqkvLyTHg6D4bMbBB12uPp",
    reservedProviderDashboardTwilioQueueGetId: "ccb062e7-f3f7-43de-a1a4-bf5c9c974cd8",    
    reservedTwilioCtiVoiceMailTaskQueueFriendlyName: "Voice Mail Task Queue",
    reservedTwilioCtiVoiceMailWorkerFriendlyName: "Voice Mail Worker",
    twilioCtiApiKey: "SK5b2013fb5e65fb761630f74daa60fec4",
    twilioCtiApiSecret: "MlVCLCrOYnJyjBxgREMR1HkfOv7911mf",
    twilioCtiAccountSid: "AC1c8754517d7ed8172fce94fa2b4d0c60",
    twilioCtiAuthToken: "5b10e35b6a6662e36bf0f0c0a79c20b4",    
    twilioCtiInitialTaskPriority: 1,
    twilioCtiTaskTimeoutInSeconds: 900,
    twilioCtiDeviceTokenTimeoutInSeconds: 43200,
    twilioCtiWorkerTokenTimeoutInSeconds: 43200,
    twilioCtiConferenceKeyExpiryTimeInSeconds: 14400,
    twilioCtiWrappingMonitorIntervalInSeconds: 600,
    twilioCtiSyncId: "",
    twilioCtiS3AccessKeyId: "",
    twilioCtiS3SecretAccessKey: "",
    twilioCtiS3Region: "us-east-1",
    twilioCtiS3Bucket: "",
    twilioCtiMicroServiceUrl: `https://${uiClientsConfig.customerUrlPrefix}-twilio.${uiClientsConfig.reservedUrlDomain}`,
    // reservedCodeCategoryViewLabcorpLabResult:"tf2770fa-932e-4d20-847b-y3fb5571h8d5",
    reservedCodeCategoryViewOneOffMessaging:"bf2770fa-932e-4e20-847b-f3fb5571b8d5",
    reservedCodeCategoryCustomizableSurveyPropertyType: "a352da42-51s6-1348-87f8-7088a8a9daf6",
    reservedCodeCategoryConsolidatedSurveyAlertEmailMapping: "k342da42-51s6-1348-87f8-7088a8a9daf6",/*Consolidated Survey Alert Email Mapping */
    reservedGlobalPhoneNumberBusinessLogicId:"edee2b5c-2808-4b58-9c23-b7b227d6868e",

    /*Reserved Consolidated Survey Submitted*/
    reservedConsolidatedSurveySubmittedTriggerObject: "CONSOLIDATED_SURVEY_SUBMITTED",
    reservedConsolidatedSurveySubmittedBusinessProcessDataTriggerEventId: "34616f32-5628-6sc1-5f2a-4s21b345684b",

    /* start etc Role Permissions */
    focusMarketingAutomationRoleId: "a276614f-e4e2-4aa0-b8ae-642dc18848f9",
    focusMarketingAutomationRoleName: "focus:MarketingAutomationRole",
    etcReviewReportDashboardRoleId: "ebc1bb38-3d04-421b-9bb4-ea40d21bb6da",
    etcReviewReportDashboardRoleName: "etc:reviewReportDashboard",
    etcEmptySubordinateRoleId: "aefc9294-dfc8-48c7-b816-5a122608a732",
    etcEmptySubordinateRoleName: "etc:emptySubordinateRole",
    etcHomeId: "8141e9ae-d439-44fb-a5cf-cc0e802c6df8",
    etcHomeName: "etc:home",
    etcInterventionsId: "af7ef766-867b-4349-b7d4-5e6cec9b4c1a",
    etcInterventionsName: "etc:interventions",
    etcMemberSearchId: "f0aedb11-febf-40b3-9b9d-e5d81155b883",
    etcMemberSearchName: "etc:memberSearch",
    etcNpsDashboardId: "6f157a46-8cb9-4f56-8b5c-e9c4397dd4f4",
    etcNpsDashboardName: "etc:npsDashboard",
    etcQueuesId: "d3e350a3-d988-4dd3-868d-f2d47df17cab",
    etcQueuesName: "etc:queues",
    etcSocialReviewsId: "bcecfbc6-f59e-4443-b44b-902781d4e8a9",
    etcSocialReviewsName: "etc:socialReviews",
    reservedPaymentReminderBusinessProcessDataTriggerEventId:"1c35495e-9b54-4a3c-b192-86d75422c302",
    reservedCodeCategoryDispositionStatusId:"fcac6368-df3e-4ec8-bf6b-3e16bec519a9",
    reservedDispositionTypeDonotContactId:"f1eec657-6b9c-478f-a309-1ec3ee47b90b",
    reservedDispositionTypeOptOutId:"f74e4882-2aef-4375-a0ec-e6aae251c0a0",
    reservedDispositionTypeDeclinedId:"fe725c11-c83d-44b7-ace5-d0fa6533f46f",
    reservedDispositionTypeNotInterestedId:"4456bb83-9f27-47ac-b348-b8a2cc12f93b",
    reservedDispositionTypeFollowUpId:"9a278929-23de-498f-9384-f6f610c0f61c",
    reservedDispositionTypeScheduledId:"5efb2e51-3293-4401-980f-92bb4a82cbe7",
    reservedDispositionTypeRescheduledId:"f4a106dd-384a-497f-a31d-d0fbc6117956",
    reservedDispositionTypeNoContactId:"2b39cb97-043c-402d-a07c-10df8b9b403b",
    reservedDispositionTypeOtherId:"94f0a127-261a-4705-8562-7ebd25353aa9"
    /* end etc Role Permissions */

};

/** Temporary Config Value to hide calendar */
export var  isVirtualCareCalendarEnabled = true;
export var  isSendPortalInviteEnabled = false;
export var  isCreateNewPatientIconsEnabled = false;
export var  isCalendarMessageTemplateEnabled = true;

export var  secureMessageConfig = {
    pollTimeInterval: 2 * 1000,
    pollRetryCount: 30
};

export var  uiVersionConfig = {
     "databaseVersion": "4.57.0"
};

export var  redisConfig = {
    redisKey: "g77cb03r-9lfs-8v80-j2lf-su7e7o84594o" /**Unique UUID for Redis Key Application setting  */
};

export var  twoWayMessageConfig = {
    pollInterval: 2000 /** 1000 is 1 second */
};

export var  videoConsultParticipantStatusConfig = {
    reservedValueJoinedForVideoConsultParticipantStatus: 1,
    reservedValueActiveForVideoConsultParticipantStatus: 2,
    reservedValueInactiveForVideoConsultParticipantStatus: 3,
    reservedValueMutedForVideoConsultParticipantStatus: 4,
    reservedValueUnmutedForVideoConsultParticipantStatus: 5,
    reservedValueDisconnectedForVideoConsultParticipantStatus: 6,
    reservedValueSharedScreenForVideoConsultParticipantStatus: 7,
    reservedValueHungUpForVideoConsultParticipantStatus: 8,
    reservedValueWaitingForVideoConsultParticipantStatus: 9,
    reservedValueVideoOnForVideoConsultParticipantStatus: 10,
    reservedValueVideoOffForVideoConsultParticipantStatus: 11,
    reservedValueCalledForVideoConsultParticipantStatus: 12,
    reservedUrlDomain: uiClientsConfig.reservedUrlDomain
};

export var  quickConsultMessageCommunicationStatusConfig = {
    pollInterval: 4000, /** 1000 is 1 second */
    reservedValueQuickConsultMessageTextType: 1,
    reservedValueQuickConsultMessageImageType: 2,
    reservedValueQuickConsultMessageCommunicationMessageSentStatus: 1,
    reservedValueQuickConsultMessageCommunicationMessageSendingStatus: 2,
    reservedValueQuickConsultMessageCommunicationMessageReceivedStatus: 3,
    reservedValueQuickConsultMessageCommunicationMessageDeletedStatus: 4,
    reservedValueQuickConsultMessageCommunicationMessageUnDeletedStatus: 5,
};

export var  uiExternalConfig = {
    getLanguagePrefernceApiKey : "z09408e9-eeec-7b43-8ta8-3a6b277ca421",
    reservedCodeCategoryRatingReferralId: "7ec077d9-cb86-9462-dc1e-177cecae77d4"
};

// external Psychographic survey path without authentication 
export var  psychographicSurveyExternalLinkConfig = {
    psychographicSurveyExternalLinkPath: "psychographic-survey-external-link"
};

export var  reservedInteractionsV1QueueNameConfig = {
    "reservedInteractionsV1QueueName": "V1InteractionsQueue"
};

// consolidated external survey template type id
export var  consolidatedSurveyTemplateTypeConfig = 5;

// consolidated survey default survey type 
export var  consolidateSurveyTypeConfig = {
    survey: 1,
    customizableSurvey: 2
};

export var  consolidatedSurveyResultCurrentStatusConfig = {
    opened: "OPENED",
    inProgress: "IN_PROGRESS",
    completed: "COMPLETED"
};

// reservedCodeCategories.twilioCtiMicroServiceUrl = `https://${uiClientsConfig.customerUrlPrefix}-twilio.${uiClientsConfig.reservedUrlDomain}`;